import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-secondary-card',
  templateUrl: './secondary-card.component.html',
  styleUrls: ['./secondary-card.component.scss'],
})
export class SecondaryCardComponent {
  @Input() id: string | number = '';
  @Input() name: string = '';
  @Input() nameEN: string = '';
  @Input() offerName: string = '';
  @Input() offerNameEN: string = '';
  @Input() image: string = '';
  @Input() redeemDate: any = '';
  @Input() isUniversalCode: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() isLoading: boolean = false;
  @Input() isUsed: boolean = false;
  @Input() isTimeLeft: any = false;

  subscription!: Subscription;
  lang!: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.subscription = this.translocoService.langChanges$.subscribe(
      (activeLang) => {
        this.lang = activeLang;
      }
    );
  }

  get Title() {
    return this.lang === 'th' ? this.offerName : this.offerNameEN;
  }

  onClickButton(event: any) {
    if (!this.isUsed || !this.isTimeLeft) {
      // this.isLoading = true;
      this.onClick.emit(event);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
