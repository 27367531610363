<div class="container mx-auto py-12 px-6 lg:px-16">
  <ng-container *ngIf="!isLoading">
    <div class="gap-4 grid grid-cols-1 lg:grid-cols-2" *ngIf="offerDetail">
      <div
        class="bg-[#F7F7F7] w-full rounded-2xl flex items-center justify-center min-h-72 md:h-96"
      >
        <div class="relative bg-white w-full h-full rounded-xl">
          <img
            [ngSrc]="offerDetail.offerImage"
            alt="image"
            class="w-full h-full object-cover rounded-xl"
            fill
            priority
          />
          <div
            *ngIf="offerDetail?.category"
            class="absolute bg-white text-xs z-40 p-1.5 top-4 left-4 rounded-lg"
          >
            {{ offerDetail.category?.toLocaleUpperCase() }}
          </div>
        </div>
      </div>
      <div
        class="bg-[#F7F7F7] w-full p-5 rounded-2xl flex flex-col justify-between"
      >
        <div>
          <div class="flex items-center gap-5">
            <div
              class="w-14 h-14 shrink-0 grow-0 rounded-full shadow-md shadow-gray-300 bg-white border-t-2 overflow-hidden relative"
            >
              <img
                [ngSrc]="offerDetail.merchantImage"
                fill
                priority
                class="w-full h-full object-contain"
                alt="logo"
              />
            </div>

            <span>{{
              lang === "th"
                ? offerDetail.merchantName
                : offerDetail.merchantNameEN
            }}</span>
          </div>

          <h1
            [innerHTML]="Title"
            class="text-lg sm:text-2xl tracking-tight mt-5"
          ></h1>
          <p class="text-sm opacity-50 mt-5">
            {{
              lang === "th"
                ? offerDetail.shortDescription
                : offerDetail.shortDescriptionEN
            }}
          </p>
          <div class="mt-5 flex gap-2">
            <mat-icon>access_time</mat-icon>
            <div>{{ offerDetail.offerStart }} - {{ offerDetail.offerEnd }}</div>
          </div>
          <p class="text-main-red" *ngIf="offerDetail.status === 'expired'">
            ** {{ "Expired" | transloco }} **
          </p>
        </div>
        <div>
          <div class="lg:flex gap-4 lg:gap-2 grid grid-cols-12 mt-10 lg:mt-0">
            <ng-container *ngIf="isUser && role === 'USER' && !isQuotaLoading">
              <div
                *ngIf="
                  offerDetail.offerType !== 'WELCOME' &&
                  offerDetail.status === 'start' &&
                  offerDetail.code
                "
                class="col-span-10"
              >
                <app-primary-button
                  [content]="'Get Code'"
                  (onClick)="onGetCode()"
                ></app-primary-button>
              </div>
              <div
                *ngIf="
                  offerDetail.offerType === 'WELCOME' &&
                  offerDetail.status === 'start'
                "
                class="col-span-10"
              >
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="'Redeem Offer'"
                    [isDisable]="isRedeemed"
                    (onClick)="onConfirm()"
                  ></app-primary-button>
                </ng-container>
                <ng-container *ngIf="isRedeemed">
                  <app-primary-button
                    [content]="
                      !offerDetail.isUsaged ? 'See Wallet' : 'Redeemed'
                    "
                    [isDisable]="offerDetail.isUsaged"
                    routerLink="/history"
                  ></app-primary-button>
                </ng-container>
              </div>
              <div
                *ngIf="
                  offerDetail.offerType === 'WELCOME' &&
                  offerDetail.status === 'pause'
                "
                class="col-span-10"
              >
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="'Redemption Pause'"
                    [isDisable]="true"
                  ></app-primary-button>
                </ng-container>
                <ng-container *ngIf="isRedeemed">
                  <app-primary-button
                    [content]="
                      !offerDetail.isUsaged ? 'See Wallet' : 'Redeemed'
                    "
                    [isDisable]="offerDetail.isUsaged"
                    routerLink="/history"
                  ></app-primary-button>
                </ng-container>
              </div>
              <div
                *ngIf="
                  offerDetail.offerType === 'WELCOME' &&
                  offerDetail.status === 'full'
                "
                class="col-span-10"
              >
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="'Redemption Full'"
                    [isDisable]="true"
                  ></app-primary-button>
                </ng-container>
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="
                      !offerDetail.isUsaged ? 'See Wallet' : 'Redeemed'
                    "
                    [isDisable]="offerDetail.isUsaged"
                    routerLink="/history"
                  ></app-primary-button>
                </ng-container>
              </div>
              <!-- <div
                *ngIf="offerDetail.offerType === 'WELCOME' && isFullQuota"
                class="col-span-10"
              >
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="'Fully Redeemed'"
                    [isDisable]="true"
                  ></app-primary-button>
                </ng-container>
                <ng-container *ngIf="!isRedeemed">
                  <app-primary-button
                    [content]="
                      !offerDetail.isUsaged ? 'See Wallet' : 'Redeemed'
                    "
                    [isDisable]="offerDetail.isUsaged"
                    routerLink="/history"
                  ></app-primary-button>
                </ng-container>
              </div> -->
            </ng-container>

            <div
              *ngIf="!isUser && role !== 'USER' && !isQuotaLoading"
              class="col-span-10"
            >
              <ng-container
                *ngIf="offerDetail.offerType === 'WELCOME' && !offerDetail.code"
              >
                <app-primary-button
                  [content]="'Redeem Offer'"
                  (onClick)="onOpenLoginDialog()"
                ></app-primary-button>
              </ng-container>
              <ng-container
                *ngIf="offerDetail.code && offerDetail.offerType !== 'WELCOME'"
              >
                <app-primary-button
                  [content]="'Get Code'"
                  (onClick)="onOpenLoginDialog()"
                ></app-primary-button>
              </ng-container>
            </div>

            <div class="col-span-10 lg:w-36" *ngIf="isQuotaLoading">
              <app-loading-button></app-loading-button>
            </div>

            <button
              (click)="onOpenShareDialog()"
              class="rounded-full border-2 w-8 sm:w-10 h-8 sm:h-10 flex items-center justify-center border-black col-span-1 ransition ease-in-out hover:scale-105 duration-150"
            >
              <mat-icon>share</mat-icon>
            </button>
          </div>
          <p
            *ngIf="
              offerDetail.offerType === 'WELCOME' &&
              offerDetail.status === 'full' &&
              !isFullQuota
            "
            class="text-sm text-[#6F6F6F] mt-2.5 lg:mt-5"
          >
            {{ "This offer has been fully redeemed" | transloco }}
          </p>
          <p
            *ngIf="
              offerDetail.offerType === 'WELCOME' &&
              offerDetail.status === 'pause' &&
              !isFullQuota
            "
            class="text-sm text-[#6F6F6F] mt-2.5 lg:mt-5"
          >
            {{
              "This offer is temporarily unavailable for redemption. We apologize for any inconvenience"
                | transloco
            }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="mt-10 grid grid-cols-2 text-center text-2xl md:text-4xl font-medium border-b-2"
    >
      <div
        *ngFor="let tab of tabs; let i = index"
        (click)="selectTab(i)"
        class="cursor-pointer transition ease-in-out active:scale-110 duration-150 text-gray-300 font-[Ontima]"
        [ngClass]="{ 'tab-item': true, active: i === selectedTab }"
      >
        {{ tab.title | transloco }}
      </div>
    </div>
    <div class="mt-10" *ngIf="offerDetail">
      <ng-container [ngSwitch]="tabs[selectedTab].title">
        <div *ngSwitchCase="'Offer Detail'">
          <div
            *ngIf="lang === 'th'"
            class="text-[#6F6F6F] text-pretty"
            [innerHTML]="offerDetail.offerDetail | transformInnerHTML"
          ></div>
          <div
            *ngIf="lang === 'en'"
            class="text-[#6F6F6F] text-pretty"
            [innerHTML]="offerDetail.offerDetailEN | transformInnerHTML"
          ></div>
        </div>
        <div *ngSwitchCase="'Terms & Conditions'">
          <div
            *ngIf="lang === 'th'"
            class="text-[#6F6F6F] text-pretty"
            [innerHTML]="offerDetail.terms | transformInnerHTML"
          ></div>
          <div
            *ngIf="lang === 'en'"
            class="text-[#6F6F6F] text-pretty"
            [innerHTML]="offerDetail.termsEN | transformInnerHTML"
          ></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <app-main-loading></app-main-loading>
  </ng-container>
  <div class="mt-32" *ngIf="!isLoading">
    <h1 class="text-5xl font-[Ontima-bold] font-bold">
      {{ "More Interest Offer" | transloco }}
    </h1>
    <div class="flex flex-col md:flex-row md:items-center md:justify-center">
      <div
        *ngIf="!isMoreLoading"
        class="flex flex-col w-full md:flex-row md:flex-wrap gap-4 md:items-stretch md:justify-start lg:max-w-[720px] xl:max-w-[1088px] mt-8"
      >
        <ng-container *ngFor="let content of offerList">
          <app-main-card
            [routerLink]="['/offer', content._id]"
            [queryParams]="{
              card: card
            }"
            [id]="content._id"
            [name]="content.merchantName"
            [nameEN]="content.merchantNameEN"
            [title]="content.offerName"
            [titleEN]="content.offerNameEN"
            [logo]="content.merchantImage"
            [image]="content.offerImage"
            [desc]="content.shortDescription"
            [descEN]="content.shortDescriptionEN"
            [topTag]="this.visaTag[this.card]"
          ></app-main-card>
        </ng-container>
      </div>
      <app-main-loading *ngIf="isMoreLoading"></app-main-loading>
    </div>
  </div>
</div>
